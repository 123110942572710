<script setup lang="ts">

</script>

<template>
  <div id="inputContextMenu" class="dropdown clearfix">
    <ul class="dropdown-menu" role="menu" aria-labelledby="dropdownMenu"
        style="display:block;position:static;margin-bottom:5px;">
      <li class="downloadItem">
        <a tabindex="-1" href="#">
          <i class="glyphicon glyphicon-cloud-download"/>
          Download
        </a>
      </li>
    </ul>
  </div>
</template>

<style scoped>

</style>