<script setup lang="ts">


</script>

<template>
  <span class="spacer"/>
</template>

<style scoped>
.spacer {
  width: var(--hex-viewer-spacer-width);
  display: inline-block;
  margin: 0;
  padding: 0;
}

</style>